<template>
    <div>
        <input type="range"
               v-bind:min="min"
               v-bind:max="max"
               v-bind:step="step"
               :value="value"
               v-on:input="updateValue($event.target.value)">
    </div>
</template>

<script>
    export default {
        name: "Slider",
        props: {
            min: {
                type: Number,
                default: 0
            },
            max: {
                type: Number,
                default: 100
            },
            step: {
                type: Number,
                default: 1
            },
            value: {
                type: Number
            }
        },
        methods: {
            updateValue(value) {
                this.$emit('change', Number(value));
            }
        },
    }
</script>

<style lang="scss" scoped>
    div {
        margin: 1rem auto 2rem;
        max-width: 400px;
    }
    input[type=range] {
        display: block;
        width: 100%;
    }
</style>