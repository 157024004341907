<template>
  <div class="home">
    <Gauges v-bind:level="level" v-bind:pressure="pressure" />
    <LevelSlider v-on:change="setLevel" :value="level" />
    <PressureSlider v-on:change="setPressure" :value="pressure" :max=10 :step=.1 />
<!--    <HelloWorld msg="Welcome to Vue.js App"/>-->
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import Gauges from "@/components/Gauges.vue";
import LevelSlider from "@/components/Slider.vue";
import PressureSlider from "@/components/Slider.vue";

export default {
  name: 'Home',
  components: {
    // HelloWorld,
    Gauges,
    LevelSlider,
    PressureSlider
  },
  data () {
    return {
      // level: this.$store.state.level,
      // pressure: this.$store.state.pressure,
    }
  },
  computed: {
    level() {
      return this.$store.state.level
    },
    pressure() {
      return this.$store.state.pressure
    }
  },
  methods: {
    setLevel(value) {
      this.$store.state.level = value
    },
    setPressure(value) {
      this.$store.state.pressure = value
    }
  }
}
</script>
