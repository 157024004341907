import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'

Vue.config.productionTip = false
Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    level: -1,
    pressure: -1,
    wsconn: null
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
