<template>
    <div class="gauges">
        <div class="gauge">
            <LevelGauge :value="level" :options="optionslevel"></LevelGauge>
        </div>
        <div class="gauge">
            <PressureGauge :value="pressure" :options="optionspressure"></PressureGauge>
        </div>
    </div>
</template>

<script>
    import LevelGauge from 'vue2-canvas-gauges/src/RadialGauge'
    import PressureGauge from 'vue2-canvas-gauges/src/RadialGauge'
    export default {
        name: "Gauges",
        components: {
            LevelGauge,
            PressureGauge
        },
        props: {
            level: {
                type: Number,
                default: -1
            },
            pressure: {
                type: Number,
                default: -1
            },
            title: {
                type: String,
                default: ''
            },
            optionslevel: {
                type: Object,
                default: () => ({
                    width: 160,
                    height: 160,
                    title: "level",
                    units: "%",
                    minValue: 0,
                    valueBox: false,
                    maxValue: 100,
                    majorTicks: [0,10,20,30,40,50,60,70,80,90,100],
                    minorTicks: 2,
                    strokeTicks: true,
                    highlights: [
                        { "from": 0, "to": 10, "color": "rgba(50, 50, 200, .75)" },
                        { "from": 90, "to": 100, "color": "rgba(200, 50, 50, .75)" }
                    ],
                    colorPlate: "transparent",
                    borderShadowWidth: 0,
                    borders: false,
                    needleType: "arrow",
                    needleShadow: false,
                    needleWidth: 2,
                    needleCircleSize: 7,
                    animation: false
                })
            },
            optionspressure: { // https://canvas-gauges.com/documentation/user-guide/configuration
                type: Object,
                default: () => ({
                    width: 160,
                    height: 160,
                    title: "pressure",
                    units: "bar",
                    minValue: 0,
                    valueBox: false,
                    maxValue: 10,
                    majorTicks: [0,1,2,3,4,5,6,7,8,9,10],
                    minorTicks: 2,
                    strokeTicks: true,
                    highlights: [
                        { "from": 0, "to": 1, "color": "rgba(50, 50, 200, .75)" },
                        { "from": 8, "to": 10, "color": "rgba(200, 50, 50, .75)" }
                    ],
                    colorPlate: "transparent",
                    borderShadowWidth: 0,
                    borders: false,
                    needleType: "arrow",
                    needleShadow: false,
                    needleWidth: 2,
                    needleCircleSize: 7,
                    animation: false
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    div.gauges {
        margin: 0 auto;
        max-width: 400px;
        display: flex;
        flex-wrap: nowrap;
    }
    div.gauge {
        flex: 1 0 50%;
    }
    h3 {
        font-size: 12px;
    }
</style>