<template>
  <div id="app">
    <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link> |
      <router-link to="/ixp">IXP</router-link>
    </div>
    <router-view/>
  </div>
</template>

<script>
  export default {
    name: 'App',
    methods: {
      receiveMessage(message) {
        console.log(message);
        this.$store.state.level = Number(message);
      }
    },
    created() {
      const model = this;
      console.log("Starting connection to WebSocket Server")
      this.$store.wsconn = new WebSocket("ws://10.48.91.166:81/")
      this.$store.wsconn.onmessage = function(event) {
        model.receiveMessage(event.data)
      }
      this.$store.wsconn.onopen = function(event) {
        console.log(event)
        console.log("Successfully connected to the websocket server...")
      }
    }
  }
</script>

<style lang="scss">
body {
  /*background-color: #e4c4ff;*/
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 0 30px 30px;
  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
