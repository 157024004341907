<template>
    <div class="about">
        <h1>IXP internet services</h1>
        <p>No more hanging please!</p>
        <p>Really?!</p>
        <p>Yes indeed!</p>
        <p>Now it is {{ currenttime }}</p>
    </div>
</template>

<script>
    export default {
        name: "IXP",
        data () {
            return {
                currenttime: new Date()
            }
        }
    }
</script>

<style scoped>

</style>